html {
  height: 100%;
}
body {
  min-height: 100%;
  overscroll-behavior-y: none;
  overflow: hidden;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.menu {
  font-size: 14px;
  /* background-color: #fff; */
  width: 250px;
  height: auto;
  margin: 0;
/* use absolute positioning  */
  position: absolute;
  list-style: none;
  /* box-shadow: 0 0 20px 0 #ccc; */
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 9999;
}

.z-top {
  z-index: 9999;
}

.transparent-button, .transparent-button:active, .transparent-button:focus, .transparent-button:hover {
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0 0 transparent !important;
}

.scrollable-content-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}