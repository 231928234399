:root {
    --now-playing-card-height: 70px;
}

.nav-button {
    background-color: var(--bs-secondary);
}

.nav-button:active {
    background-color: var(--bs-primary);
}

.nav-host {
    height: 75px;
}

.now-playing-card {
    position: absolute;
    bottom: 80px;
}

.now-playing-card-height {
    height: var(--now-playing-card-height);
    min-height: var(--now-playing-card-height);
}